/* Variables */
@import 'settings';

/* Foundation Utilities */
@import '~foundation-sites/scss/foundation';
@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-visibility-classes;

/* Slick Slider */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Animate CSS */
// @import '~animate.css/animate.css';
@import '~aos/dist/aos.css';

/* Lightbox */
@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';

/* Datepicker */
@import '~flatpickr/dist/flatpickr.min.css';

/* Fonts */
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

/* abril-fatface-regular - latin */
@font-face {
  font-family: 'Abril Fatface';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/abril-fatface-v10-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Abril Fatface'), local('AbrilFatface-Regular'),
    url('../fonts/abril-fatface-v10-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/abril-fatface-v10-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/abril-fatface-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/abril-fatface-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/abril-fatface-v10-latin-regular.svg#AbrilFatface')
      format('svg');
  /* Legacy iOS */
}

@import '../fonts/quicksand';
@import '../fonts/montserrat';

/* Utilities */
@import 'utils/utils';
@include mk-generic-margin-padding();

/* Layout */
@import 'layout/footer';
@import 'layout/grid';
@import 'layout/hero';
@import 'layout/page';
@import 'layout/section';
@import 'layout/sidebar';

/* Components */
@import 'components/ads';
@import 'components/alert';
@import 'components/btn';
@import 'components/calendar';
@import 'components/card';
@import 'components/cards';
@import 'components/chart';
@import 'components/chip';
@import 'components/contact';
@import 'components/company';
@import 'components/cta';
@import 'components/dialog';
@import 'components/features';
@import 'components/form';
@import 'components/gallery';
@import 'components/icons';
@import 'components/label';
@import 'components/list';
@import 'components/login';
@import 'components/logo';
@import 'components/map';
@import 'components/media';
@import 'components/menu';
@import 'components/topbar';
@import 'components/pagination';
@import 'components/panel';
@import 'components/payment';
@import 'components/posts';
@import 'components/preloader';
@import 'components/slider';
@import 'components/swipeable';
@import 'components/tooltip';

#root {
  height: 100%;
}

.link {
  position: relative;
  font-weight: bold;
  padding-bottom: rem-calc(5);
  cursor: pointer;
  transition: all 0.2s;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    border-color: transparent;
    border-style: solid;
    border-width: 0;
    transition: all 0.2s;
  }

  &--primary {
    color: get-color(primary);

    &:hover:after {
      border-bottom: 1px solid get-color(primary);
    }
  }
}

.dashboard-panel {
  padding: $gutter-base;
  padding-top: rem-calc($topnav-height + $gutter-base);
  padding-bottom: rem-calc($gutter-base * 1.5) !important;
  height: 100%;
  background-color: $body-background-color;
}

.dashboard-panel__add-icon {
  position: fixed !important;
  z-index: 9;
  bottom: rem-calc(calc($gutter-base / 2));
  right: rem-calc(calc($gutter-base / 2));
}

.tile {
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.tile__content {
  padding: rem-calc(32);
}

.tile__footer {
  padding: rem-calc(32);
}

.tile__content + .tile__footer {
  padding-top: 0;
}

.bg {
  &--success {
    background-color: get-color(success) !important;
    color: white !important;
  }

  &--danger {
    background-color: get-color(alert) !important;
    color: white !important;
  }

  &--info {
    background-color: get-color(info) !important;
    color: white !important;
  }

  &--warning {
    background-color: get-color(warning) !important;
    color: white !important;
  }

  &--travel {
    @include mk-gradient(
      #ece9e6,
      $iniColor: #ece9e6,
      $endColor: white,
      $iniTransp: 52,
      $endTransp: 52,
      $angle: 60.9
    );

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../img/bg-travel.svg');
      background-size: 50%;
      opacity: 0.07;
    }
  }
}

.text-color {
  &.text-secondary {
    color: $text-secondary;
  }

  &.success {
    color: get-color(success);
  }

  &.danger {
    color: get-color(alert);
  }

  &.warning {
    color: get-color(warning);
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.btn.loading {
  cursor: not-allowed;
  pointer-events: none;
  position: relative;

  &:after {
    content: '';
    background-color: inherit;
    background-image: url('../img/bars.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }
}

@include breakpoint(lg) {
  .dashboard-panel {
    padding-left: rem-calc($sidebar-width + $gutter-base);
  }
}

@include breakpoint(xs only) {
  .dashboard-panel {
    padding: rem-calc(calc($gutter-base / 2));
    padding-top: rem-calc($topnav-height + calc($gutter-base / 1.5));
  }
}

@import 'utils/generic-modifiers';
