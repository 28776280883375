.cards {
  @include mk-flexbox($flow: row wrap, $justify: flex-start, $align: initial);
  @include mk-gutter-xy(margin, -25);
  width: auto;

  &--slider {
    & .slick-list {
      overflow: visible;
    }

    & .slick-dots {
      margin-top: rem-calc(50);
    }

    & .cards__item {
      @include mk-gutter-xy(margin, 0);
      @include mk-gutter-xy(padding, 0);
    }
  }

  &--small-padding {
    @include mk-gutter-xy(margin, -15);

    & .cards__item {
      @include mk-gutter-xy(padding, 15);
    }
  }
}

.cards-slider-wrapper {
  width: 120%;
  margin: rem-calc(25) 0;
  margin-left: -10%;

  @include breakpoint(small only) {
    width: 270%;
    margin-left: -85%;

    .slick-dots {
      margin-top: rem-calc(15);
    }
  }
}

.cards__item {
  position: relative;
  @include mk-gutter-xy(padding, 25);
  width: 100%;

  & .card {
    min-height: 100%;
  }
}

/* ------------------------------
DISPLAY LIST AND GRID 
------------------------------ */
.cards--list,
.cards--grid {
  & .card__title {
    font-size: rem-calc(16);
    color: white;
  }

  & .card__container {
    padding: rem-calc(15);
  }
}

.cards--list {
  & .card__title {
    text-align: left;
  }
}

.cards--grid {
  & .cards__item {
    @extend .sm-6, .md-4 !optional;
  }

  & .card__media-wrapper .card__container {
    height: 100%;
    justify-content: center;
  }
}

/*
--------------------------------------------------
MEDIA QUERIES 
--------------------------------------------------
*/
@include breakpoint(small only) {
  .cards {
    @include mk-gutter-xy(margin, -25);

    & .cards__item {
      padding-top: rem-calc(17.5);
      padding-bottom: rem-calc(17.5);
    }

    &--slider {
      padding: 0;

      & .cards__item {
        padding: rem-calc(25) rem-calc(10);
        margin: 0;
      }

      & .card__media-wrapper {
        &:before {
          padding-bottom: 70vh;
        }

        & .card__container {
          height: 100%;
        }

        & .card__body {
          max-height: 100%;
          opacity: 1;
        }
      }
    }
  }

  .cards--list {
    @include mk-gutter-xy(margin, -5);

    & .card__title {
      font-size: rem-calc(14);
    }

    & .cards__item {
      @include mk-gutter-xy(padding, 5);
    }
  }

  .cards--grid {
    @include mk-gutter-xy(margin, -5);

    & .cards__item {
      @include mk-gutter-xy(padding, 5);
    }

    & .card__media-wrapper .card__container {
      & .card__title {
        font-size: rem-calc(13);
      }
    }
  }
}
