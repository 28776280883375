@include mk-spacing();

.main-grid {
    @include mk-flexbox($grid: true);
    margin: 0 auto;
    height: auto;
    min-height: 100%;
}

.grid {
    display: block;
    position: relative;
    width: 100%;
}

.grid__container {
    @include mk-flexbox(row, $justify:flex-start);

    &--column {
        flex-direction: column;
        flex-wrap: nowrap;
        float: none;

        &>.grid__item {
            width: 100%;
        }
    }


}

.grid__item {

    &.auto {
        max-width: none;
        flex-basis: auto;
    }

    &>.grid__container {
        height: 100%;
    }
}