.hero {
    position: relative;
    text-align: center;
    height: 30vh;
    padding-bottom: rem-calc($gutter-base*2);

    &.about {
        height: 60vh;

        &>.container {}

        & .hero__content {
            margin-top: 0;
        }
    }

    &.post {

        height: 40vh;
        width: 100%;

        & .hero__content {
            margin-top: 5vh;
        }



        @include breakpoint(xs only) {
            height: 60vh;

            & .hero__content {
                margin-top: 10vh;
            }


        }

    }

    &>.container {
        @include mk-flexbox($align:center, $justify:center, $grid:true);
        margin: 0 auto;
        z-index: 1;
        height: 100%;
    }

    @include breakpoint(xs only) {
        height: 40vh;
    }
}

.hero__bg {
    position: absolute;
    background-position: center center;
    background-size: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    @include add-filter($angle: 45, $iniColor: darken(#00ADEE, 5%), $iniTransp:.85, $endColor: darken(#1685C3, 5%), $endTransp:.85);

}

.hero__content {
    margin-top: auto;
}

.hero__title {
    display: block;
    font-size: rem-calc(36);
    color: white;

    @include breakpoint(xs only) {
        font-size: rem-calc(26);
    }
}

.hero__headline {
    display: block;
    font-size: rem-calc(18);
    color: white;

    @include breakpoint(xs only) {
        font-size: rem-calc(16);
    }
}