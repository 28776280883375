.react-calendar {
  width: 100%;
  // width: 350px;
  // max-width: 100%;
  // background: white;
  // border: 1px solid rgb(160, 160, 150);
  // font-family: Arial, Helvetica, sans-serif;
  font-family: inherit;
  font-size: rem-calc(16);
  // line-height: 1.125em;
  & abbr,
  & abbr[title] {
    cursor: inherit;
    border: none;
  }
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    font-size: inherit;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    transition: 0.2s all;

    &:enabled {
      color: get-color(primary);
      font-weight: 600;
      &:hover {
        color: #fff;
        background-color: get-color(primary);
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;
      // color: get-color(primary);
      // font-weight: 600;

      // &:enabled {
      //   &:hover,
      //   &:focus {
      //     background-color: rgba(230, 230, 230, 0.35);
      //   }
      // }

      &:disabled {
        color: $text-secondary;
        background-color: rgb(240, 240, 240);
        opacity: 0.3;
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;

      &__weekday {
        padding: 0.5em;
      }
    }

    &__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: red;
        }

        &--neighboringMonth {
          color: rgb(150, 150, 150);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;

    &:disabled {
      background-color: rgb(240, 240, 240);
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--hasActive,
    &--active {
      background: darken(get-color(primary), 10%) !important;
      color: white !important;

      &:enabled {
        &:hover,
        &:focus {
          background: get-color(primary) !important;
        }
      }
    }
  }
  // .react-calendar {
  //   &__tile--active,
  //   &__tile--hasActive {
  //     background-color: get-color(primary) !important;
  //     color: white !important;
  //   }
  // }
  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}

@include breakpoint(xs only) {
  .react-calendar {
    font-size: rem-calc(14);
  }
}
// .react-calendar {
//   width: 100%;
//   border: none;
//   font-size: rem-calc(16);
//   font-family: inherit;
// }
// .react-calendar * {
//   font-size: rem-calc(16);
// }
