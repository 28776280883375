$switch-width: 48;
$toggle-size: 20;

.switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: rem-calc($switch-width);
    height: rem-calc($toggle-size);

    & * {
        transition: .2s all;
    }
}

.switch__toggle {
    @extend %depth-1;
    position: absolute;
    z-index: 1;
    left: 0;
    width: rem-calc($toggle-size);
    height: rem-calc($toggle-size);
    border-radius: rem-calc($toggle-size);
    background-color: white;
}

.switch__trail {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    border-radius: rem-calc($toggle-size);
    background-color: rgba(black, .07);
    box-shadow: inset 0 0 2px rgba(black, .1);
}

.switch__checkbox {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 2;
    cursor: pointer;

    &:checked {
        &~.switch__toggle {
            left: rem-calc($switch-width - $toggle-size);
            background-color: get-color(primary);
        }

        &~.switch__trail {
            background-color: rgba(get-color(primary), .5);
        }
    }
}

.switch__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:empty {
        display: none;
    }

    &--on {
        left: 0;
        color: lighten(get-color(primary), 20%)
    }

    &--off {
        right: 0;
        color: rgba(black, .54);
    }
}