.pagination {
    padding: rem-calc($gutter-base) 0;

    &>.container {
        @include mk-flexbox(row, $justify:center);
        list-style: none;
    }
}

.pagination__item {
    margin: rem-calc(5);

    & a,
    & span {
        padding: rem-calc(10);
    }
}