.contact__item {
    margin-bottom: rem-calc(20);

    & .description {
        font-weight: 700;
        margin-bottom: rem-calc(5);

        &--sub {
            color: $text-secondary;
            font-weight: 600;
            font-size: 90%;
            margin: 0;
        }
    }

    & .line {
        margin: 0;
    }
}