.form {
  width: 100%;
  max-width: rem-calc(600);
  margin: 0 auto;
}

.form-control {
  display: flex;
  position: relative;
  margin-bottom: rem-calc(25);
  padding-top: rem-calc(15);

  text-align: left;
  font-size: rem-calc(15);
  color: $text-primary;

  transition: .2s all;


  &.is-active,
  &.is-filled {

    & .form-control__label {
      transform: translate(0, 0) scale(0.8);
      top: 0;
      left: 0;
      font-style: normal;
    }
  }

  .form-control__label.up {
    transform: translate(0, 0) scale(0.8);
    top: 0;
    left: 0;
    font-style: normal;
  }


  &.is-active {
    & .form-control__label {
      color: get-color(primary);
      opacity: 1;
    }
  }

  &:hover {

    & input:not([type=button]),
    textarea {
      box-shadow: 0 1px 0 0 $light-gray;
    }
  }

  &.is-active {

    & input:not([type=button]),
    textarea {
      box-shadow: 0 1px 0 0 get-color(primary);
      border-bottom: 1px solid get-color(primary);
    }
  }

  & input:not([type=button]),
  textarea,
  select,
  .--as-input {
    border: none;
    border-bottom: 1px solid $light-gray;
    transition: .2s all;
    width: 100%;
    background: transparent;
    color: inherit;
    font-size: inherit;
    padding: 0;
    min-height: rem-calc(30);
    line-height: 1.8;
  }

  & textarea {
    margin-top: rem-calc(5);
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &--select {

    &:after {
      @extend %fa-icon;
      @extend .fas;

      content: fa-content($fa-var-angle-down);
      position: absolute;
      right: 0;
      top: 50%;
    }

    // padding-top: 0;

    // & select {

    //   height: inherit;
    //   transition: .2s all;
    //   width: 100%;
    //   background: transparent;
    //   color: inherit;
    //   font-size: inherit;
    //   border-bottom: 1px solid $light-gray;
    // }
  }



  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   border: 1px solid transparent;
  //   border-bottom-width: 1px;
  //   border-bottom-style: solid;
  //   border-bottom-color: rgba(black, .2);
  //   transition: .2s all;
  // }

  &--select.error {
    border-bottom-color: get-color(alert);
    box-shadow: 0 1px get-color(alert);
  }

  &.error {
    margin-bottom: rem-calc(40);

    & input:not([type=button]),
    textarea {
      border-bottom-color: get-color(alert);
      box-shadow: 0 1px get-color(alert);
    }
  }

}

.form__subtitle {
  font-size: rem-calc(17);
  color: $medium-gray;
  margin-bottom: rem-calc(15);
  font-weight: bold;
}

.form-response {
  @extend .card__body;
  color: white;
  margin-bottom: rem-calc($gutter-base);

  &.success {
    background-color: get-color(success);
  }

  &.error {
    background-color: get-color(alert);
  }

  &:empty {
    display: none;
  }
}

.form-messages {
  margin: 0;
  margin-bottom: rem-calc($gutter-base);
  padding: 0;

  & .message {
    @extend .card__body;
    transition: .2s all;
  }

  & li {
    list-style: none;
  }
}

@include mk-component-palette('message');


// .form-control__radio {
//   @extend .btn;
//   border-bottom: none;


//   & input[type=radio]:checked {
//     @extend 
//     // margin-top: rem-calc(24);
//     // margin-bottom: rem-calc(6);
//     // border: none;
//     // transition: .2s all;
//     // width: 100%;
//     // background: transparent;
//     // color: inherit;
//     // font-size: inherit;
//   }
// }

.form-control__label {
  position: absolute;
  transform: translate(0, rem-calc(18)) scale(1);
  transform-origin: top left;

  top: 0;
  left: 0;

  opacity: .3;
  font-weight: 400;
  font-size: 90%;
  transition: .2s all;

  font-style: italic;

}

.form-control__btn {
  color: inherit;
  cursor: pointer;
}

.form-control__action-btns {
  @include mk-flexbox(row, $align:center);
  margin: rem-calc(-4);
  margin-top: rem-calc($gutter-base);

  & .action-btn {
    margin: rem-calc(4)
  }
}

.helper-text {
  position: absolute;
  top: 110%;
  font-size: rem-calc(11.5);
  width: 100%;
  transition: all .2s ease-in-out;
  color: rgba($text-primary, .54);

  &:empty {
    display: none;
  }
}

.error .helper-text,
.helper-text .errorlist {
  color: get-color(alert);
  font-weight: bold;
}

.errorlist {
  margin: 0;
  padding: 0;

  & li {
    list-style: none;
  }
}

.form-control.error {
  & .helper-text {
    display: block;
  }
}

.form-control__captcha {
  position: relative;

  &:after {
    border-bottom-width: 0 !important;
  }

  &>div {
    display: inline-block;
    position: relative;
  }
}


@include breakpoint(xs only) {
  .form {
    padding-bottom: rem-calc(50);
  }
}

@import './switch.scss'