.chip {
  cursor: inherit !important;
  font-size: rem-calc(13);
  border-radius: rem-calc(100);
  padding: rem-calc(7) rem-calc(12);
  transition: .2s all;
  min-height: rem-calc(6);

  &--big {
    font-size: rem-calc(16);
  }

  & span {
    transition: 0.2s all;
  }

  &--status {
    font-size: rem-calc(10);
    letter-spacing: 1px;
    font-weight: bold;
    font-weight: 900;
    text-transform: uppercase;
    padding: rem-calc(1) rem-calc(8);
    height: auto;
  }
}


@include mk-component-palette('chip');
//   &.success {
//     @extend .bg--success;
//     color: white;
//   }
//   &.info {
//     @extend .bg--info;
//     color: white;
//   }
//   &.danger {
//     @extend .bg--danger;
//     color: white;
//   }
//   &.warning {
//     @extend .bg--warning;
//   }
//   &.condensed span {
//     font-size: 0;
//   }
// }