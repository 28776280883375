.company {
    @extend .card;
}

a.company {
    @extend .elevate;
}

.company__body {
    @extend .card__body;
}

.company__logo {
    @extend .media-wrapper,
    .media-wrapper--square;
}

.company__name {
    @extend .card__title;
    margin: 0;
}

.company__line-detail {
    &--last {
        @include breakpoint(md) {
            text-align: right;
        }
    }
}

.company--single {
    flex-direction: row;
    flex-wrap: wrap;

    & .company__content {
        @extend .card__body;
        width: 40%;
        flex: 1;
    }

    & .company__photo {
        @extend .media-wrapper;
        width: 60%;
        border-radius: 0 3px 3px 0;
    }

    & .company__line-detail {

        & i,
        & span {
            display: inline-block;
            vertical-align: middle;
            margin-right: rem-calc(10);
        }

    }
}

@include breakpoint(xs only) {

    .company--single {
        & .company__content {
            width: 100%;
        }

        & .company__photo {
            width: 100%;
            border-radius: 0 0 3px 3px;
        }
    }
}