.menu {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  top: 0;
  left: 0;
  background: white;
  overflow-y: scroll;

  // color: white;

  width: 100%;
  min-height: 100vh;
  height: 100%;

  transition: 0.2s all;

  & [data-aos] {
    opacity: 1 !important;
    transition: none;
    transform: none;
  }

  &.showing {
    opacity: 1;
    z-index: 999;
    visibility: visible;
  }

  & > .container {
    height: 100%;
  }

  & .close-btn {
    z-index: 9;
    // color: white;
    font-size: rem-calc(20);
    opacity: 0.7;
    transition: 0.2s all;

    &:hover {
      opacity: 1;
    }
  }

  & .logo {
    height: rem-calc(40);
    // filter: brightness(0) invert(1);

    @include breakpoint(xs only) {
      height: rem-calc(30);
    }
  }

  & .nav {
    @include mk-flexbox(column, $justify: space-between);
    margin: rem-calc(calc($gutter-base / 2 * -1));

    & .nav__item {
      padding: rem-calc(calc($gutter-base / 2 * -1));
    }
  }
}

.menu__content {
  padding: rem-calc($gutter-base) rem-calc(calc($gutter-base / 2));
}

.menu__item {
  position: relative;
  height: 100%;
  transition: 0.3s all ease-in-out;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.menu__logo-container {
  @include mk-flexbox($justify: center);
  height: 100%;
}

.menu__btn {
  font-size: rem-calc(22);
  line-height: 1.5;
  margin-bottom: rem-calc(15);
  font-weight: 600;
  position: relative;
  display: inline-block;

  & a {
    color: $dark-gray;
  }

  cursor: pointer;

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid white;
    opacity: 0;
    transition: 0.2s all;
  }

  @include breakpoint(xs only) {
    font-size: rem-calc(20);
  }
}
