.slider-wrapper {
    @include mk-flexbox(row, $align:center);

    &.home {
        height: 90vh;
    }
}

.slider {
    height: inherit;
    cursor: move;
    width: 100%;

    & .slide {
        overflow: hidden;
        position: relative;
    }

    &--with-filter {
        & .slide {
            @include add-filter($angle: 180, $iniColor: #394557, $iniTransp:.2, $endColor: #252b34, $endTransp:.35);
        }
    }

    &--with-arrows {
        padding: 0 rem-calc(50);
    }
}

.slide>.container {
    padding: rem-calc(15);
    transition: .3s all;
}

@include breakpoint(sm down) {
    .slider--with-arrows {
        padding: 0;

        & .slick-arrow {
            display: none !important;
        }
    }

    .slide>.container {
        padding: 0;
    }

    .slide:not(.slick-current)>.container {
        padding: rem-calc(10);
    }
}

.slick-list,
.slick-track,
.slick-slide {
    height: inherit !important;
}



.slide__img {
    width: 100%;
    height: inherit;
    max-height: inherit;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
}

.fullScreen.on.contain {
    & .slide__img {
        object-fit: contain;
    }
}

.slide__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide__content {
    position: absolute;

    text-align: left;
    bottom: 20%;
    left: 10%;
}

.slide__title {
    font-size: rem-calc(100);
    font-family: 'Abril Fatface', cursive;

    @include breakpoint(sm down) {
        font-size: rem-calc(50);
    }
}

.slide__text {
    font-size: rem-calc(30);

    @include breakpoint(sm down) {
        font-size: rem-calc(20);
    }
}

.slide__title,
.slide__text {
    color: white;
}

.slide__btn {
    @extend .btn,
    .btn--primary,
    .floater;
    margin-top: rem-calc($gutter-base);
}

.slick-arrow {
    @extend .btn,
    .btn--circle;
    width: auto !important;
    height: auto !important;

    &:before {
        color: get-color(primary) !important;
        font-size: rem-calc(30) !important;
    }

    &.slick-prev {
        left: rem-calc(15);
    }

    &.slick-next {
        right: rem-calc(15);
    }
}