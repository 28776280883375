.footer {
  & > .container {
    @include mk-flexbox(row, $justify: space-between, $grid: true);
    margin: 0 auto;
  }

  // @include breakpoint(md down) {
  //     padding: rem-calc($gutter-base*2) rem-calc(calc($gutter-base / 2));

  //     &>.container {
  //         flex-direction: column;
  //         width: 100%;
  //     }

  //     & .footer__item {
  //         margin-bottom: rem-calc($gutter-base *1.5);
  //     }
  // }
  // @include breakpoint(xs) {
  //     padding: rem-calc($gutter-base*2) rem-calc(calc($gutter-base / 2));

  //     &>.container {
  //         flex-direction: column;
  //         width: 100%;
  //     }

  //     & .footer__item {
  //         margin-bottom: rem-calc($gutter-base *1.5);
  //     }
  // }
}

.social-media__icon {
  font-size: rem-calc(30);
}

.footer__content {
  width: 100%;

  @include breakpoint(md down) {
    padding: 0 rem-calc(calc($gutter-base / 2));
  }
}

.footer__item {
  @extend .grid__item;
  color: white;

  & .item__title {
    font-weight: 700;
    opacity: 0.35;
  }

  & .item__content + .item__content {
    margin-top: rem-calc(5);
  }
}

.footer-copy {
  padding: rem-calc(calc($gutter-base / 2)) 0;
  background: darken(get-color(secondary), 5);
  font-size: 80%;
  font-weight: 300;
  color: white;

  & > .container {
    @include mk-flexbox(row, $grid: true, $justify: space-between);
    margin: 0 auto;

    @include breakpoint(sm down) {
      justify-content: center;
    }
  }

  .copy__item {
    @extend .grid__item;
  }

  & .designer {
    text-align: right;
    font-size: 90%;

    & a {
      color: inherit;
      opacity: 0.25;
      transition: 0.2s all;

      &:hover {
        opacity: 1;
      }
    }

    &span {
      display: inline-block;
    }
  }
}
