.about {
  & .feature {
    & .content {
      padding: 20%;
    }

    @include breakpoint(xs only) {
      & .content {
        padding: 0;
      }
    }
  }
}

.features {
  @include mk-flexbox(row);

  @include breakpoint(sm) {
    & .feature:nth-child(even) > .container {
      flex-direction: row-reverse;
    }
  }

  @include breakpoint(xs only) {
    & .feature > .container {
      padding: rem-calc($gutter-base * 2) rem-calc($gutter-base);
    }

    & .feature + .feature > .container {
      padding-top: 0;
    }
  }
}

.feature {
  width: 100%;

  & > .container {
    @include mk-flexbox(row, $justify: center);
  }

  & .content {
    flex: 1;
    text-align: center;
    font-size: rem-calc(15);
  }
}

.feature__icon {
  font-size: rem-calc(40);
  color: get-color(primary);
  margin-bottom: rem-calc(10);
}

.feature__title {
  font-size: rem-calc(17);
  margin-bottom: rem-calc(7);
  font-weight: 700;
}

.feature__image {
  @extend .media-wrapper;
  height: 100%;

  &--in-content {
    @extend .media-wrapper--wide;
    display: none;
    margin: rem-calc($gutter-base) 0 rem-calc(calc($gutter-base / 2));
  }

  @include breakpoint(xs only) {
    display: none;

    &--in-content {
      display: block;
    }
  }
}
