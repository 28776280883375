.preloader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  & + * {
    transition: 0.2s all;
    opacity: 0.25;
  }
}
