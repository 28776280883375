.posts {
  @extend .grid;

  &--home {
    padding: rem-calc($gutter-base * 3) 0;
  }
}

.post {
  @extend .card;
}

.post__body {
  @extend .card__body;

  & .post__thumb {
    padding-bottom: rem-calc(15);
  }

  & p img {
    padding: 0 rem-calc(15) rem-calc(15) 0;
  }
}

.post__header {
  @extend .card__header;
  justify-content: space-between;
  align-items: stretch;

  & .post__title {
    font-weight: 700;
    font-size: rem-calc(17);
    margin-bottom: 0;
  }
}

.single .post {
  & .post__header {
    padding-bottom: rem-calc(25);
  }

  & .page__title {
    margin-bottom: rem-calc(3);
  }
}

.post__featured-img {
  @extend .card__section;
  overflow: hidden;

  & img {
    width: 100%;
  }
}

.post__title {
  font-weight: 700;
  font-size: rem-calc(17);
  margin-bottom: rem-calc(3);
}

.post__publish-date {
  font-size: rem-calc(12);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: rem-calc(3);
  color: $text-secondary;

  & i {
    color: get-color(primary);
  }
}

.post__btns {
  padding: rem-calc(24);
}

.post__body + .post__btns {
  padding-top: 0;
}

.post__dates {
  font-size: rem-calc(15);
  font-weight: 600;
  color: $text-secondary;
  text-transform: uppercase;

  & i {
    font-size: rem-calc(24);
  }
}

.post__related {
}

.post__related-title {
  font-size: rem-calc(18);
  font-weight: 700;
  margin-bottom: rem-calc($gutter-base);

  &:before {
    content: '';
    width: rem-calc(50);
    display: block;
    height: rem-calc(3);
    background-color: get-color(primary);
    margin-bottom: rem-calc(calc($gutter-base / 2));
  }
}
