.section {
  position: relative;

  & > .container:not(.fullWidth) {
    @include mk-flexbox($grid: true);
  }

  & > .container {
    margin: 0 auto;
    min-height: 30vh;
    padding-top: rem-calc($gutter-base * 2);
    padding-bottom: rem-calc($gutter-base * 3);
  }

  &--related {
    & > .container {
      padding-top: 0;
    }
  }

  &--features {
    & > .container {
      min-height: 50vh;
    }

    & .section__title {
      font-weight: bolder;
      font-size: rem-calc(62);
      font-family: 'Abril Fatface', cursive;

      @include breakpoint(sm down) {
        font-size: rem-calc(40);
      }
    }

    & .section__headline {
      font-size: rem-calc(22);

      @include breakpoint(sm down) {
        font-size: rem-calc(18);
      }
    }

    @include breakpoint(md down) {
      & > .container {
        padding: rem-calc($gutter-base * 1.5);
      }
    }
  }

  // Sizes
  &--min-height {
    min-height: 60vh;
  }

  &--fullscreen {
    min-height: 100vh;
  }

  &--halfscreen {
    height: 50vh;
  }

  &--dark-filter:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);
  }

  &--filter:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: darken(rgba(get-color(secondary), 0.35), 30%);
  }

  &--white {
    background-color: white;
  }

  &--transparent {
    background-color: transparent;
  }
}

.section__icon {
  max-width: rem-calc(30);
  display: inline-block;
  margin-bottom: rem-calc(10);
}

.section__icon--empty {
  font-size: rem-calc(60);
  opacity: 0.2;
  max-width: none;
}

.section__spacer {
  padding-bottom: rem-calc(35);
}

.section__footer {
  padding-top: rem-calc($gutter-base * 2);
}

.section__content {
  padding: 10%;

  &--card-floater-l {
    @extend .card;

    @include breakpoint(md) {
      margin-left: rem-calc(-65);
    }
  }
}

.section__footer,
.section__header {
  padding-bottom: rem-calc($gutter-base * 1.5);

  &:last-child {
    padding-bottom: 0;
  }

  @include breakpoint(xs only) {
    padding-bottom: rem-calc(calc($gutter-base / 2));
  }
}

.section__header {
  @include mk-flexbox(column, stretch, center);

  &:only-child {
    padding-bottom: 0;
  }

  & .section__icon {
    padding-bottom: 0;
  }
}

.section--item-detail {
  & .section__container {
    padding-top: 0;
    margin-top: -7vh;
  }

  & + .section .section__container {
    padding-top: 0;
  }
}

/* ------------------------------------
GRID
------------------------------------- */
.section__grid {
  @include mk-flexbox($flow: row wrap, $justify: space-between);
  @include mk-gutter-xy(margin, -35);
  width: auto;

  & + .section__grid {
    flex-direction: row-reverse;
    padding-top: rem-calc(50);
  }
}

.section__grid-item {
  @include mk-gutter-xy(padding, 35);
  width: 100%;
}

.section__row {
  margin-bottom: rem-calc(25);
}

.section__title {
  font-family: 'Abril Fatface', cursive;
  font-size: rem-calc(36);
  font-weight: 300;
  color: darken(get-color(primary), 15%);
  margin: 0;

  @include breakpoint(sm down) {
    font-size: rem-calc(28);
  }
}

.section__headline {
  font-size: rem-calc(22);

  @include breakpoint(sm down) {
    font-size: rem-calc(18);
  }
}

.section__subtitle {
  @extend p, .small;
  color: $medium-gray;

  &--small {
    @extend p, .smaller;
  }
}

.section__tagline {
  text-align: center;
  margin-top: rem-calc(5);

  &--highlight {
    font-size: rem-calc(15);
    text-transform: uppercase;
    font-weight: 700;
    color: $dark-gray;
    position: relative;
  }
}

/*
-------------------------------------------------------------------------------
MEDIA QUERIES 
-------------------------------------------------------------------------------
*/

// @include breakpoint(xlarge down) {
//   .section__title {
//     font-size: rem-calc(50);
//   }
// }

@include breakpoint(md down) {
  .section--fullscreen {
    align-items: center;
  }

  .section__img {
    height: auto;
  }

  .section > .container:not(.fullWidth) {
    padding-left: rem-calc($gutter-base * 1.5);
    padding-right: rem-calc($gutter-base * 1.5);
  }
}

@include breakpoint(xs only) {
  .section > .container:not(.fullWidth) {
    padding-top: rem-calc($gutter-base);
    padding-left: rem-calc(calc($gutter-base / 2));
    padding-right: rem-calc(calc($gutter-base / 2));
  }

  .section--related > .container {
    padding-top: 0;
  }

  .section__container {
    padding: rem-calc(15) rem-calc(10);
  }

  .section__grid {
    @include mk-gutter-xy(margin, -12.5);
  }

  .section__grid-item {
    @include mk-gutter-xy(padding, 12.5);
    text-align: center;
  }

  .section__row {
    margin-bottom: rem-calc(30);
  }

  //   .section__title {
  //     align-self: center;
  //     text-align: center;
  //     font-size: rem-calc(20);
  //     margin-top: rem-calc(25);

  //     &:after {
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
  //   }

  .section__text {
    text-align: center;
  }

  .section--item-detail .section__container {
    margin-top: -12vh;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
}
