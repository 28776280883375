.drawer>div {
  border: none;
}

.sidebar-wrapper {
  @include add-filter(#1b1b1b, $opacity: 0.74);
  background-color: $dark-gray;
  // background-image: url('assets/img/bg-train.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100%;
}

.sidebar {
  position: relative;
  width: $sidebar-width;
  padding: 0;
  z-index: 1;

  & * {
    font-family: $body-font-family;
  }

  & .list-item__icon {
    color: inherit;
  }

  & .list-item__text {
    color: inherit;
  }

  &__link {
    display: block;
    text-decoration: none;
    color: $text-white;

    & span,
    & svg {
      transition: 0.5s all;
      opacity: 0.5;
      color: inherit;
    }

    & svg {
      font-size: rem-calc(22);
    }

    &:hover {
      color: $text-white !important;

      & span,
      & svg {
        opacity: 1;
        color: inherit;
      }
    }

    &.active {
      color: $white !important;
      opacity: 1;

      & span,
      & svg {
        opacity: 1;
        color: inherit;
      }

      & span {
        font-weight: 600;
      }
    }
  }

  &__logo {
    padding: rem-calc($gutter-base * 1.5) rem-calc($gutter-base) rem-calc($gutter-base);
    text-align: center;

    & img {
      width: auto;
      height: 100%;
      max-height: rem-calc(100);

      @include breakpoint(xs only) {
        max-height: rem-calc(80);
      }
    }
  }
}