/*
--------------------------------------------------------------------------------
BACKGROUND AND COLOR

quaternary, quinary, senary, septenary, octonary, nonary, and denary
--------------------------------------------------------------------------------
*/

@include mk-palette-gradients();

@include mk-element-pallete($class-name: '--primary-dark',
  $color: get-color(primary),
  $add-pct: -50);
@include mk-element-pallete($class-name: '--primary-darker',
  $color: get-color(primary),
  $add-pct: -70);

[class*='--secondary'] {
  background-color: get-color(secondary);
}

[class*='--light-gray'] {
  background-color: $light-gray;
}

[class*='--medium-gray'] {
  background-color: $medium-gray;
}

[class*='--dark-gray'] {
  background-color: $dark-gray;
  color: white;
}

[class*='--newspaper-first-letter']::first-letter {
  // font-size: 400%;
  // font-weight: bolder;
  // font-family: 'Times New Roman', Times, serif;
  // float: left;

  color: get-color(secondary);
  float: left;
  font-family: Georgia;
  font-size: 80px;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  // font-family: $header-font-family;
}

/*
  --------------------------------------------------------------------------------
  TEXT
  --------------------------------------------------------------------------------
  */
@import 'modifiers-text';

/*
  --------------------------------------------------------------------------------
  LAYOUT - Rows and Columns
  --------------------------------------------------------------------------------
  */

// --- Flow

[class*='--space-between'] {
  justify-content: space-between;
}

[class*='--space-around'] {
  justify-content: space-around;
}

[class*='--row-reverse'] {
  flex-direction: row-reverse;
}

[class*='--col-reverse'] {
  flex-direction: column-reverse;
}

[class*='--sm-show'] {
  display: none;
}

@include breakpoint(xs only) {
  [class*='--row-reverse'] {
    flex-flow: row wrap;
  }

  [class*='--col-reverse'] {
    flex-flow: column wrap;
  }

  [class*='--sm-reverse-row'] {
    flex-direction: row-reverse;
  }

  [class*='--sm-reverse-col'] {
    flex-direction: column-reverse;
  }

  [class*='--sm-hide'] {
    display: none;
  }

  [class*='--sm-show'] {
    display: block;
  }
}

// --- Align Items
[class*='--align-initial'] {
  align-items: initial;
}

[class*='--align-start'] {
  align-items: flex-start;
}

[class*='--align-end'] {
  align-items: flex-end;
}

[class*='--align-center'] {
  align-items: center;
}

[class*='--center'] {
  justify-content: center;
  align-items: center;
}

// --- Justify Content
[class*='--justify-start'] {
  justify-content: flex-start;
}

[class*='--justify-end'] {
  justify-content: flex-end;
}

[class*='--justify-center'] {
  justify-content: center;
}

// --- Self
[class*='--alignself-center'] {
  align-self: center;
}

[class*='--alignself-start'] {
  align-self: flex-start;
}

[class*='--alignself-end'] {
  align-self: flex-end;
}

[class*='--justifyself-end'] {
  justify-self: flex-end;
}

// --- Float
[class*='--fl-left'] {
  float: left;
}

[class*='--fl-right'] {
  float: right;
}

/*
  --------------------------------------------------------------------------------
  HELPERS
  --------------------------------------------------------------------------------
  */

[class*='--gutterBottom'] {
  margin-bottom: rem-calc(calc($gutter-base / 2));
}

[class*='--nomargin'] {
  margin: 0 !important;
}

[class*='--nopadding'] {
  padding: 0 !important;
}

[class*='--noshadow'] {
  box-shadow: none !important;
}

[class*='--noborder'] {
  border: none !important;
}

[class*='--overflow-visible'] {
  overflow: visible;
}

[class*='--ds-block'] {
  display: block;
}

[class*='--ds-table100'] {
  display: table;
  width: 100%;
}

[class*='--ds-inlineblock'] {
  display: inline-block;
  width: 100%;
}

[class*='--ds-flex'] {
  display: flex;
  width: 100%;
}

[class*='--ds-flex-row'] {
  flex-direction: row;
}

[class*='--ds-flex-col'] {
  flex-direction: column;
}

[class*='--icon-32'] {
  width: 32px;
  height: 32px;
}

[class*='--icon-24'] {
  width: 24px;
  height: 24px;
}

[class*='--gutter-base'] {
  padding: rem-calc($gutter-base)
}

[class*='--filter-white'] {
  filter: brightness(0) invert(1);
}