table.list {
  border-collapse: collapse;
}

.list {
  width: 100%;
}

.list__item {
  // padding: 0 rem-calc(calc($gutter-base / 3));
  transition: all 0.2s;

  &:not(&--title):hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  white-space: normal !important;
  height: auto !important;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(black, 0.05);
  }

  &--small {
    font-size: 90%;
  }

  & td,
  & th {
    padding: rem-calc(5) rem-calc(7);
    white-space: nowrap;
    display: table-cell;
    text-align: left;

    @include breakpoint(xs only) {
      padding: rem-calc(5) rem-calc(15);
    }
  }

  & td:first-child,
  & th:first-child {
    width: rem-calc(50);
  }


  &>.content {
    @include mk-flexbox(row, $justify: space-between, $align: center);
    padding: rem-calc(calc($gutter-base / 3));

    & .last {
      margin-left: auto;
    }

    @include breakpoint(xs only) {
      flex-wrap: wrap;
      padding: rem-calc(calc($gutter-base / 4));

      & .last--linebreak {
        flex: 100%;
      }
    }
  }
}

.item__chip,
.item__text {
  display: inline-block;
  vertical-align: middle;
}

.item__chip {
  // width: rem-calc(25);
  // height: rem-calc(6);
  // border-radius: rem-calc(5);
  // margin-right: rem-calc(3);
}

.item__text {
  margin: rem-calc(5);
  font-size: rem-calc(14);

  // &:last-child {
  //   margin-right: 0;
  //   margin-left: auto;
  // }
}

@include breakpoint(xs only) {
  .item__chip {
    width: rem-calc(10);
    height: rem-calc(10);
  }

  .item__text {
    margin: rem-calc(3);
    font-size: rem-calc(13);
  }
}

// .list-item__icon {
//   margin-right: rem-calc(15) !important;
// }
.list-item__icon+.list-item__text {
  padding-left: 0 !important;
}

.list-item__text {
  font-family: $body-font-family;
  color: $text-primary;

  & .primary,
  & .secondary {
    font-family: $body-font-family;
    margin-bottom: 0;
    line-height: 1.3;
  }

  & .primary {
    font-weight: 600;
    color: get-color(primary);
    font-size: rem-calc(16);
  }

  & .secondary {
    font-size: rem-calc(14);
    color: $text-secondary;
  }
}