.elevation {
    transition: all .3s ease-in-out;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    z-index: 1;
    transform: scale3d(1, 1, 1);

    &:hover,
    &:focus {
        z-index: 2;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        transform: scale3d(1, 1, 1);
    }

    &:active {
        @extend %depth-0;
        transform: scale3d(1, 1, 1);
        transition: box-shadow .2s, transform .2s;
        z-index: 2;
    }
}

.elevated {
    @extend %depth-2;
    transform: scale3d(1.02, 1.02, 1.02);
}

.elevated-high {
    @extend %depth-3;
    transform: scale3d(1.02, 1.02, 1.02);
}

.elevated-higher {
    @extend %depth-4;
    transform: scale3d(1.1, 1.1, 1.1);

    @include breakpoint(xl down) {
        transform: scale3d(1.08, 1.08, 1.08);
    }
}

.elevate {
    @extend .elevation,
    %depth-1;

    &:hover:not(:active),
    &:focus:not(:active) {
        @extend .elevated;
    }
}


.elevate-high {
    @extend .elevation,
    %depth-2;

    &:hover:not(:active),
    &:focus:not(:active) {
        @extend .elevated-high;
    }
}

.elevate-higher {
    @extend .elevated-high;

    &:hover:not(:active),
    &:focus:not(:active) {
        @extend .elevated-higher;
    }
}


.elevate-btn {
    @extend .elevate;
    @extend %depth-1;

    &:hover:not(:active),
    &:focus:not(:active) {
        @extend %depth-2;
        transform: scale3d(1.03, 1.03, 1.03);
    }
}