 .logo {
     transition: all .5s;
     width: auto;

     & img {
         height: rem-calc(36);
         width: inherit;
         display: inline-block;
     }
 }

 .logo__adornment {
     font-family: 'Quicksand';
     font-weight: 400;
     color: $medium-gray;
     font-size: rem-calc(30);
     display: inline-block;
     vertical-align: middle;

     &--end {
         padding-left: rem-calc(20);
         margin-left: rem-calc(20);
         border-left: 1px solid $medium-gray;
     }
 }

 @include breakpoint(sm down) {
     .logo img {
         height: rem-calc(30);
     }

     .logo__adornment {
         font-size: rem-calc(15);
         font-weight: 600;
         display: block;
         text-align: right;
         padding: 0;
         padding-top: rem-calc(4);
         margin: 0;
         border: none;
         line-height: 1;
     }
 }