.topbar {
  // @include add-filter($angle: 180, $iniColor: #394557, $iniTransp:.1, $endColor: #252b34, $endTransp:0);
  position: fixed;
  width: 100%;

  top: 0;
  left: 0;

  border-bottom: 1px solid rgba(black, .05);
  background: white;

  transition: all .5s !important;
  z-index: 9;

  &>.container {
    @include mk-flexbox(row, $justify: space-between, $align:center, $grid:true);
    height: rem-calc(80);
    margin: 0 auto;
  }

  &.sticky {
    @extend %depth-1;
    border-bottom: none;
    // background: get-color(primary);

    // & .nav__item {
    //   color: white;
    // }

    // & .logo {
    //   filter: brightness(0) invert(1);
    // }
  }


}

.topnav {
  @include mk-flexbox(row, $align:center);
  height: 100%;

  & .nav__item:not(.openMenu) {
    @include breakpoint(sm down) {
      display: none;
    }
  }

  & .nav__item.openMenu {
    @include breakpoint(md) {
      display: none;
    }
  }
}

.nav {
  // @include mk-flexbox(row, $justify:space-around, $align:center);
  @extend .grid__container,
  .spacing-16;
  height: 100%;
  margin: 0;
}

.nav__item {
  @extend .grid__item;
  @include mk-flexbox(row, $align:center, $justify:center);
  position: relative;
  height: 100%;
  flex: 1;
  font-family: 'Quicksand' !important;
  font-size: rem-calc(14);
  white-space: nowrap;

  & a {
    font-weight: bold;
  }

  &.active {
    & a {
      color: $medium-gray;
    }

    &:before {
      content: '';
      position: absolute;
      height: rem-calc(3);
      background-color: get-color(primary);
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }

  @include breakpoint(xs only) {
    font-size: rem-calc(14);
  }
}