.login-page {
  @include mk-flexbox($align: center, $justify: center);
  @include add-filter(#1b1b1b, $opacity: 0.74);
  min-height: 100vh;
  height: 100%;
  background-color: $dark-gray;
  // background-image: url('assets/img/bg.jpg');
  background-size: cover;
  background-position: center;
  caret-color: white;

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus input:-webkit-autofill,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px $dark-gray inset !important;
    -webkit-text-fill-color: $white !important;
    -webkit-tap-highlight-color: $white !important;
  }
}

.login__box {
  @extend .card;
  @include add-filter(#1b1b1b, $opacity: 0.74);
  background: transparent;
  max-width: rem-calc(450);
  z-index: 2;
  color: $text-white;

  & * {
    color: $text-white;
    z-index: 1;
  }
}

.login__input {
  color: $text-white;

  & .form-control__input {
    color: $white !important;

    & input {
      padding: rem-calc(7);
    }

    &:before {
      border-bottom-color: rgba($white, 0.5) !important;
    }

    &:before:hover {
      border-bottom-color: $white !important;
    }

    &[class*='-focused-']:after {
      border-bottom-color: get-color(primary);
    }

    &.with-icon {
      & .icon {
        position: absolute;
      }

      &--start {
        & input {
          padding-left: rem-calc(30);
        }

        & .icon {
          left: rem-calc(6);
        }
      }

      &--end {
        & input {
          padding-right: rem-calc(30);
        }

        & .icon {
          right: rem-calc(6);
        }
      }
    }
  }

  & .form-control__label {
    color: rgba($text-white, 0.5);

    &[class*='-focused-'] {
      color: get-color(primary);
    }
  }
}

.login__box-body {
  @extend .card__body;
  position: relative;
}

.login__box-header {
  @extend .card__section;
  padding: rem-calc($gutter-base * 2) rem-calc($gutter-base);
}

.login__logo {
  max-width: rem-calc(100);
  margin: 0 auto;
  display: block;
}

@include breakpoint(xs only) {
  .login-page {
    align-items: stretch;
    flex-flow: row;
    padding: 0;
  }
}