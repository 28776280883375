.cta {
    position: relative;
    @extend .bg--primary;
    text-align: center;
    padding: 15vh 0;

    &>.container {
        @include mk-flexbox($align:center, $justify:center, $grid:true);
        margin: 0 auto;
        z-index: 1;
    }

}

.cta__bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    filter: brightness(0);
    opacity: .1;
    z-index: 0;
}

.cta__title {
    font-size: rem-calc(36);
    font-weight: 300;
}

.cta__btn {
    @extend .btn;
    color: white;
    background: none;
    border: 1px solid white;

    &:hover {
        background: rgba(black, .1);
    }
}